import Plugin from './plugin';

export default class Accordion extends Plugin {
  constructor({ container = '', items = '', label = '', collapse = '', activeClass = '' }) {
    super();
    
    this.container = container;
    this.items = items;
    this.label = label;
    this.collapse = collapse;
    this.activeClass = activeClass;

    this.init();
  }

  init() {
    this.onLoad();

    document.addEventListener('click', (e) => {
      if (e.target.matches(this.label)) {
        e.target.parentNode.classList.toggle(this.activeClass);
      }
    });
  }

  onLoad() {
    if (document.querySelectorAll(this.items)) {
      [...document.querySelectorAll(this.items)].map((item) => {
        const status = item.dataset.status;

        if (status === 'true') {
          item.classList.add(this.activeClass);
        }
      });
    }
  }
}
