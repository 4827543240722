import axios from 'axios';

document.addEventListener('click', async (e) =>
{
    if (e.target.matches('[data-button-action="add-to-quote"]'))
    {
        e.preventDefault();
        e.target.setAttribute('disabled', true);

        const id_customer = e.target.dataset.idCustomer;
        const id_product = e.target.dataset.idProduct;
        const id_product_attr = e.target.dataset.idProductAttribute;
        const action = e.target.dataset.action;
        const ajaxUrl = e.target.dataset.ajaxUrl;

        const data = new FormData();
        data.set('id_customer', id_customer);
        data.set('id_product', id_product);

        if(typeof id_product_attr !== "undefined")
        {
            data.set('id_product_attr', id_product_attr);
        } 

        data.set('action', action);

        const request = await axios.post(ajaxUrl, data);

        if (request.data.redirect)
        {
            window.location.href = request.data.redirect;
        }

        if (request.data.success)
        {
           location.reload();
        }
    }

    if ( e.target.matches('[data-button-action="add-all-to-cart"]') )
    {
        e.preventDefault();
        e.target.setAttribute('disabled', true);

        const id_customer = e.target.dataset.idCustomer;
        const action = e.target.dataset.action;
        const ajaxUrl = e.target.dataset.ajaxUrl;

        let products = []
        const _products = document.querySelectorAll(".quote__miniature");
        for (const _product of _products)
        {
            console.log(_product);
            var _prod = 
            {
                "id_product" : _product.dataset.idProduct,
                "id_product_attribute" : _product.dataset.idProductAttribute,
            }
            products.push(_prod);
        }

        const data = new FormData();
        data.set('id_customer', id_customer);
        data.set('products', JSON.stringify(products));
        data.set('action', action);

        const request = await axios.post(ajaxUrl, data);

        if (request.data.redirect)
        {
            window.location.href = request.data.redirect;
        }
    }
});

if ( document.getElementById('module-quotebuilder-customer') ) {
    document.addEventListener('click', e => {
        if ( e.target.matches('.quote-toggle') ) {
            location.reload();
        }
    })
}
