import axios from 'axios';
import Plugin from './plugin';

export default class InfiniteScroll extends Plugin {
  constructor({ page = 1, productList = '#js-product-list', loadMoreButton = '#load-more', loadSpinner = '.infinite__spinner' }) {
    super();

    this.page = page;
    this.productList = productList;
    this.loadMoreButton = loadMoreButton;
    this.loadSpinner = loadSpinner;
    this.fired = false;
    this.href = window.location.href;

    this.init();
  }

  init() {
    if (this.productList) {
      this.loadMore();
    }
  }

  loadMore() {
    document.addEventListener('click', async (e) => {
      if (e.target.matches(this.loadMoreButton)) {
        const spinner = document.querySelector(this.loadSpinner);

        spinner.classList.add(`${this.loadSpinner.replace('.', '')}--visible`);
        let res = await this.fetchAsync(this.page + 1);
        spinner.classList.remove(`${this.loadSpinner.replace('.', '')}--visible`);
        this.fired = false;

        if (res.products.length === 0) {
          this.toggleProductListBottom('end');
        } else {
          // this.toggleProductListBottom('default');
          let products = await res.rendered_products.replace('<div id="js-product-list" class="row">', '').replace(/<\/div>$/, '');
          document.querySelector(this.productList).insertAdjacentHTML('beforeend', products);
        }
      }
    });
  }

  fetchAsync(page) {
    this.page = page;
    this.fired = true;

    return new Promise(async (success, error) => {
      let res = {};
      const config = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      if (window.location.href !== this.href) {
        this.page = 2;
        this.href = window.location.href;
      }

      if (this.href.indexOf('?') > -1) {
        res = await axios.get(`${this.href}&page=${this.page}&from-xhr`, config);
      } else {
        res = await axios.get(`${this.href}?page=${this.page}&from-xhr`, config);
      }

      success(res.data);
      error(console.log);
    });
  }

  toggleProductListBottom(toggle = 'default') {
    switch (toggle) {
      case 'end':
        document.querySelector('.infinite__spinner').classList.remove('infinite__spinner--visible');
        document.querySelector('.infinite__btn').classList.remove('infinite__btn--visible');
        document.querySelector('.infinite__end').classList.add('infinite__end--visible');
        break;
      default:
        document.querySelector('.infinite__end').classList.remove('infinite__end--visible');
        // document.querySelector('.infinite__spinner').classList.add('infinite__spinner--visible');
        document.querySelector('.infinite__btn').classList.add('infinite__btn--visible');
        break;
    }

    return;
  }
}
