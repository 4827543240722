import '../scss/global.scss';
import './polyfills';
import axios from 'axios';
import { tns } from 'tiny-slider/src/tiny-slider';
import { InfiniteScroll } from './plugins';
import './shared/quotebuilder';

window.addEventListener("resize", e =>
{
    if (document.documentElement.clientWidth > 999)
    {
        const menu = document.querySelector(".ets_mm_megamenu_content .mm_menus_ul");
        if (menu.style.display == "none")
        {
            menu.style.display = "block";
        }
    }
});

document.addEventListener('DOMContentLoaded', () =>
{
    document.addEventListener('click', async (e) =>
    {
        if (e.target.matches('.password-toggle'))
        {
            const target = e.target.dataset.target;
            const field = document.querySelector(target);
            field.type = field.type == 'password' ? 'text' : 'password';
            e.target.classList.toggle('input__icon--password-show');
        }
        
        if (e.target.matches('.delete_saved_order'))
        {
            e.preventDefault();
            
            const deleteOrder = confirm('Are you sure you want to delete this saved order?');
            
            if (deleteOrder)
            {
                const call = await axios.post(e.target.href);
                
                if (call.data.success)
                {
                    e.target.parentNode.remove();
                }
            }
        }
    });

    // Hiding product modal popup if accessory is added to basket within modal
    document.body.addEventListener('click', (e) => {
        if ( e.target.matches('#blockcart-modal .accessory-add-to-cart') ) {
            document.querySelector('.modal__overlay').remove();
            document.querySelector('#blockcart-modal').remove();
        }
    });
});

document.addEventListener('DOMContentLoaded', () =>
{
    console.log(`Current page: ${prestashop.page.page_name}`);
});

document.body.addEventListener('click', (e) =>
{
    const { target } = e;
    const overlay = document.querySelector('.modal__overlay');
    const cartModal = document.querySelector('#blockcart-modal');
    const mobile_menu_node = document.querySelector(".header__mobile_menu");
    const mobile_search_node = document.querySelector(".header__top");
    const menu = document.querySelector(".ets_mm_megamenu_content .mm_menus_ul");
    
    if (mobile_menu_node == target || mobile_menu_node.contains(target))
    {  
        
	    if (menu.classList.contains("selected"))
        {
            window.setTimeout( () =>
            {
                menu.classList.remove("selected");
                menu.style.display = "none";
            }, 100);
        }
        else
        {
            window.setTimeout( () =>
            {
                menu.style.display = "block";
                menu.classList.add("selected");
            }, 100);
        }
    }
    else
    {
        switch (true)
        {
            case target.matches('.continue'):
                overlay.remove();
                cartModal.remove();
                break;
            case target.matches('.modal__overlay'):
                overlay.remove();
                cartModal.remove();
                break;
            default:
                console.log(typeof(target));
                break;
        }
    }
});

const fixedNavBar = () =>
{
    window.onscroll = () => 
    {
        const { offsetHeight, classList: menu } = document.querySelector('.nav');
        const { style, offsetTop } = document.querySelector('.nav--fixed');
        const { pageYOffset } = window;
    
        switch (true)
        {
            case pageYOffset <= offsetTop:
                style.height = 0;
                menu.remove('nav--sticky');
                break;
            default:
                style.height = `${offsetHeight}px`;
                menu.add('nav--sticky');
        }
    };
};


if ( document.querySelector('#sort-by-selected') ) {
    document.addEventListener('click', e => {
        switch(true) {
            case e.target.matches('#sort-by-selected'):
                document.querySelector('#sort-by-list').classList.toggle('visible');
                break;
            default:
                document.querySelector('#sort-by-list').classList.remove('visible');
                break;
        }
    });    
}


// Manufacturer Slider
if ( document.querySelector('#best_sellers') ) {
    var miniatureSlider = tns({
        "container": '.miniature-slider',
        "controlsContainer": '.miniature-slider-navigation',
        "loop": true,
        "items": 1,
        "nav": false,
        "autoplay": false,
        "autoplayButtonOutput": false,
        "autoHeight": true,
        "autoplayTimeout": 5000,
        "speed": 600,
        "mouseDrag": true,
        "lazyload": false,
        "gutter": 20,
        "startIndex": 0,
        "responsive": {
            "480": {
                "items": 2,
            },
            "767": {
                "items": 3,
            },
            "992": {
                "items": 4,
            }
        },
    });
}

var iframes = document.querySelectorAll('.webinar-iframe');
console.log(iframes);

if(iframes)
{
    document.querySelectorAll('.webinar-iframe').forEach(function(iframe) {
        console.log(iframe);
        var src = iframe.dataset.src;
            
        iframe.setAttribute('src',src);
    });
}

// Blog Comments
if ( document.querySelector('.post__comments') ) {
    document.addEventListener('click', (e) => {
        if ( e.target.matches('.comments__title') ){
            document.querySelector('.post__comments').classList.toggle('opened');
        }
    });
}

//blog post search
var input = document.querySelector(".post__header-search .searcher");
if(input){
    input.addEventListener("keydown", function (e) {
        if (e.code === "Enter") {  //checks whether the pressed key is "Enter"
            processPostSearch();
        }
    });
}

var button = document.querySelector(".post__header-search button");
if(button)
{
    button.addEventListener('click', function (e){
       processPostSearch();
    });
}

function processPostSearch()
{
    if(input.value !== "") {
        window.location.href = "/viewall?name=post&s=" + input.value
    }
}

// Header Banners Slider
if ( document.querySelector('#header_banner_slider') ) {
    var headerBannerSlider = tns({
        "container": '#header_banner_slider',
        "controlsContainer": '.header__banner__navigation',
        "loop": true,
        "items": 1,
        "nav": false,
        "autoplay": true,
        "autoplayButtonOutput": false,
        "autoHeight": true,
        "autoplayTimeout": 5000,
        "speed": 600,
        "mouseDrag": true,
        "gutter": 20,
        "slideBy": 1,
        "responsive": {
            "600": {
                "items": 2,
            },
            "991": {
                "items": 3,
            },
            "1200": {
                "items": 4,
            }
        },
    });
}

document.addEventListener('click', (e) => {
    [...document.querySelectorAll('.blog__sidebar-block .heading .collapse-icons i')].map(icon => {
        if ( e.target==icon ) {
            icon.parentNode.parentNode.parentNode.classList.toggle('show');
        }
    });
});

// Infinite scroll
const scroll = new InfiniteScroll({
    page: 1, 
    productList: '#js-product-list', 
    loadMoreButton: '#load-more', 
    loadSpinner: '.infinite__spinner'
});

// Sticky Searchbar
document.addEventListener('click', (e) => {
    const searchToggle = document.querySelector('#sticky_search_toggle');
    const searchBar = document.querySelector('#sticky_searchbar');


    switch (true) {
        case e.target.matches('#sticky_search_toggle'):
            searchBar.classList.toggle('visible');
            break;
        case searchBar.contains(e.target):
            break;
        default:
            searchBar.classList.remove('visible');
            break;
    }
});